      import React, { useState, useEffect } from 'react';
      import { Helmet } from 'react-helmet';
      import Playlist from '../components/Playlist';
import fetchFavorites from '../FavoritesHelpers'; // import helper functions

      import { useAuth } from '../components/Auth'; // import useAuth
      const PlaylistPage = (props) => {
        const { user } = useAuth(); // Get the signed-in user from Auth context
        const [favorites, setFavorites] = useState([]);
        useEffect(() => {
          if (user && user.uid) { // Ensure user is available before fetching data
            const fetchData = async () => {
              const favoritesArray = await fetchFavorites(user.uid);
              setFavorites(favoritesArray);
            };
            fetchData();
          }
        }, [user]);
        // Add a favorite item
        
        return (
          <>
            <div className="playlist-page-container">
              <Helmet>
                <title>Favorites: {user.uid}</title>
              </Helmet>
              <div className="playlist-page-header">
                <h1>Favorites</h1>
              </div>
             
              {favorites.map(favorite => (
                <div key={favorite.id}>
                  <Playlist
                    id={favorite.id}
                    title={favorite.Title}
                    EpisodeDuration={favorite.Duration}
                    EpisodeUrl={favorite.Episodeurl}
                  />
                  
                </div>
              ))}
            </div>
      <style jsx>
        {`
          .playlist-page-container {
            width: 100%;
            display: flex;
            overflow: auto;
            min-height: 100vh;
            text-align: center;
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;
            background-color: #ffffff;
          }
          .playlist-page-container1 {
            flex: 0 0 auto;
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: var(--dl-space-space-unit);
            justify-content: center;
          }
          .playlist-page-container2 {
            flex: 0 0 auto;
            width: auto;
            height: auto;
            margin: var(--dl-space-space-halfunit);
            display: flex;
            padding: var(--dl-space-space-unit);
            align-items: center;
            border-color: rgba(120, 120, 120, 0.4);
            border-style: dashed;
            border-width: 0px;
            justify-content: center;
            background-color: #000000;
          }
          .playlist-page-icon {
            fill: #fff7f7;
            width: 24px;
            height: 24px;
          }
          .playlist-page-container3 {
            flex: 0 0 auto;
            width: auto;
            height: auto;
            display: flex;
            padding: var(--dl-space-space-unit);
            align-items: center;
            border-color: rgba(120, 120, 120, 0.4);
            border-style: dashed;
            border-width: 0px;
            justify-content: center;
            background-color: #000000;
          }
          .playlist-page-icon2 {
            fill: #ffffff;
            width: 24px;
            height: 24px;
          }
          .playlist-page-container4 {
            flex: 0 0 auto;
            width: auto;
            height: auto;
            margin: var(--dl-space-space-unit);
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .playlist-page-text {
            color: #ffffff;
            margin: var(--dl-space-space-halfunit);
            padding: var(--dl-space-space-unit);
            font-size: 24px;
            background-color: #0f0f0f;
          }
        `}
      </style>
    </>
  )
}

export default PlaylistPage
