import React, { useState, useRef, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAudioPlayer } from './Context';
import PropTypes from 'prop-types';
import { useAuth } from './Auth';
import { database } from '../firebase';
import { ref, set } from 'firebase/database'; // Import the set method
import MoreDrawer from './more-drawer';
import ReactGA from 'react-ga4';

import './dth-global-player-web.css'

const DTHGlobalPlayerWeb = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    isPlaying,
    setIsPlaying,
    audioSrc,
    setAudioSrc,
    audioTitle,
    setAudioTitle,
    audioImg,
    setAudioImg,
    isCasting,
    setIsCasting,
    isLive,
    setIsLive,
    duration,
    setDuration,
    TPBGImage,
      SetTPBGImage,
      BGImage,
      SetBGImage,
    audioSubtext,
      setAudioSubtext,
    isFav,
    setIsFav,
  } = useAudioPlayer();
  const audioRef = useRef(null);
  const { user } = useAuth();
  const [currentTime, setCurrentTime] = useState(0);
  const cjs = new Castjs();

  useEffect(() => {
    if (user) {
      const userAudioSyncRef = ref(database, `users/${user.uid}/ActiveAudioSync`);
      set(userAudioSyncRef, {
        AudioTitle: audioTitle || '',
        Duration: duration || 0,
        AudioImg: audioImg || '',
        AudioSrc: audioSrc || '',
        currentTime: currentTime || 0,
      }).catch((error) => {
        console.error("Error updating ActiveAudioSync:", error);
      });
    }
  }, [user, audioTitle, duration, audioImg, audioSrc, currentTime]);

  useEffect(() => {
    if (isCasting) {
      audioRef.current.muted = true;
      cjs.on('connected', () => {
        audioRef.current.muted = true;
        cjs.play();
      });
      cjs.on('disconnected', () => {
        audioRef.current.muted = false;
        if (isPlaying) {
          audioRef.current.play();
        }
      });
    } else if(audioRef.current) {
      audioRef.current.muted = false;
      if (isPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  }, [isCasting, isPlaying]);

  useEffect(() => {
    if (isCasting) {
      cjs.cast(audioSrc, {
        title: audioTitle,
        poster: audioImg,
      });
      cjs.on('update', state => {
        setCurrentTime(state.currentTime);
        setDuration(state.duration);
      });
    }
  }, [audioSrc, isCasting]);

  useEffect(() => {
    if (isCasting) {
      cjs.seek(currentTime);
    }
  }, [currentTime, isCasting]);

  const handlePlayPause = () => {
    setIsPlaying(prevIsPlaying => !prevIsPlaying);
    if (isCasting) {
      isPlaying ? cjs.pause() : cjs.play();
    } else {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
    }
    if (user) {
      const userAudioSyncRef = ref(database, `users/${user.uid}/ActiveAudioSync`);
      if (!isPlaying) {
        set(userAudioSyncRef, {
          AudioTitle: audioTitle || '',
          Duration: duration || 0,
          AudioImg: audioImg || '',
          AudioSrc: audioSrc || '',
          currentTime: currentTime || 0,
        }).catch((error) => {
          console.error("Error updating ActiveAudioSync:", error);
        });
      }
    }
  };

  const handleLoadedData = () => {
    setDuration(audioRef.current.duration);
  };

  useEffect(() => {
    const handleTimeUpdate = () => {
      if (!isCasting) {
        setCurrentTime(audioRef.current.currentTime);
      }
    };
    if (audioRef.current) {
      audioRef.current.addEventListener("timeupdate", handleTimeUpdate);
      audioRef.current.addEventListener("loadeddata", handleLoadedData);
    }
    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
        audioRef.current.removeEventListener("loadeddata", handleLoadedData);
      }
    };
  }, []);

  const handleSeek = (e) => {
    const seekTime = parseFloat(e.target.value);
    setCurrentTime(seekTime);
    if (isCasting) {
      cjs.seek(seekTime);
    } else {
      audioRef.current.currentTime = seekTime;
    }

    if (user && !isPlaying) {
      const userAudioSyncRef = ref(database, `users/${user.uid}/ActiveAudioSync`);
      set(userAudioSyncRef, {
        AudioTitle: audioTitle,
        Duration: duration,
        AudioImg: audioImg,
        AudioSrc: audioSrc,
        currentTime: seekTime,
      }).catch((error) => {
        console.error("Error updating ActiveAudioSync:", error);
      });
    }
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "event",
      eventCategory: "Episode",
      eventAction: "Episode Change",
      eventLabel: audioTitle,
    });

    const audio = audioRef.current;
    if (audio) {
      if (!audio.paused) {
        audio.pause();
      }
      audio.src = audioSrc;
      audio.load();
      if (isCasting) {
        audioRef.current.muted = true;
        cjs.stop();
        cjs.cast(audioSrc, {
          title: audioTitle,
          poster: audioImg,
        });
      } else {
        audio.play().catch(error => {
          console.error("Playback failed", error);
        });
      }
    }
  }, [audioSrc]);

  const { tpImage, imageAlt2 } = props;
  const history = useHistory();

  const LogoOnClick = () => {
    history.push("/");
  };

  const handleContainer5Click = () => {
    setIsModalOpen(true);
  };

  const handleModalOutsideClick = (event) => {
    if (event.target.classList.contains('modal-container')) {
      setIsModalOpen(false);
    }
  };

  const LiveRadioHandler = () => {
    setIsLive(true);
    setAudioSrc("https://Stream.thehamradio.show");
    setAudioTitle("The Ham Radio Show");
    audioRef.current.play();
  };

  
  useEffect(() => {
    const fetchIcyMetadata = async () => {
      try {
        const response = await fetch('https://streaming.live365.com/listen.pls?station=nativevoice', {
          method: 'GET',
          headers: {
            'Icy-MetaData': '1'
          }
        });
        const metadata = response.headers.get('icy-metaint');

        if (metadata) {
          const metadataInterval = setInterval(async () => {
            const reader = response.body.getReader();
            const result = await reader.read();
            if (result.done) {
              clearInterval(metadataInterval);
            }
            const decoder = new TextDecoder('utf-8');
            const text = decoder.decode(result.value);

            const match = /StreamTitle='([^;]+)';/.exec(text);
            if (match) {
              const [artist, title] = match[1].split(' - ');
              setAudioTitle(title);
              setAudioSubtext(artist);
            }
          }, 60000); // Fetch metadata every 60 seconds
        }
      } catch (error) {
        console.error('Error fetching ICY metadata:', error);
      }
    };

    if (isLive) {
      fetchIcyMetadata();
      const intervalId = setInterval(fetchIcyMetadata, 300000); // Fetch new data every 5 minutes
      return () => clearInterval(intervalId);
    }
  }, [isLive]);

  return (
    <div className="dth-global-player-web-container">
      <audio ref={audioRef} src={audioSrc} hidden></audio>
      <div className="dth-global-player-web-container1">
        {isPlaying ? (
          <svg
            viewBox="0 0 808.5942857142857 1024"
            className="dth-global-player-web-icon"
            style={{ width: "32px", height: "48px" }}
            onClick={handlePlayPause}
          >
            <path d="M270 96L270 928 370 928 370 96zM445 96L445 928 545 928 545 96z"></path>
          </svg>
        ) : (
          <svg
            viewBox="0 0 808.5942857142857 1024"
            className="dth-global-player-web-icon"
            style={{ width: "32px", height: "48px" }}
            onClick={handlePlayPause}
          >
            <path d="M790.857 529.714l-758.857 421.714c-17.714 9.714-32 1.143-32-18.857v-841.143c0-20 14.286-28.571 32-18.857l758.857 421.714c17.714 9.714 17.714 25.714 0 35.429z"></path>
          </svg>
        )}
      </div>
      <div className="dth-global-player-web-container2" onClick={LogoOnClick}>
        <img
          alt={props.imageAlt}
          src="/ham%20logo-200h.png"
          className="dth-global-player-web-image"
        />
      </div>
      <div
        id="DTH_BGCotainer"
        className="dth-global-player-web-container3"
        style={{ backgroundImage: `url(${BGImage ? BGImage : props.PlayerBG})`, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      >
        <div className="dth-global-player-web-container4" style={{ backgroundImage: `url(${TPBGImage ? TPBGImage : '/billy-nicknames-transparent-400w.png'})`, backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }}>
          <span className="dth-global-player-web-text">
            {audioSubtext ? audioSubtext : "Why? Because We Can!"}
          </span>
          <span className="dth-global-player-web-text1">{audioTitle ? audioTitle : "The Ham Radio Show"}</span>
          
          <div className="dth-global-player-web-container5" onClick={handleContainer5Click}>
            <svg viewBox="0 0 1024 1024" className="dth-global-player-web-icon2">
              <path d="M597.333 512c0-23.552-9.6-44.928-25.003-60.331s-36.779-25.003-60.331-25.003-44.928 9.6-60.331 25.003-25.003 36.779-25.003 60.331 9.6 44.928 25.003 60.331 36.779 25.003 60.331 25.003 44.928-9.6 60.331-25.003 25.003-36.779 25.003-60.331zM896 512c0-23.552-9.6-44.928-25.003-60.331s-36.779-25.003-60.331-25.003-44.928 9.6-60.331 25.003-25.003 36.779-25.003 60.331 9.6 44.928 25.003 60.331 36.779 25.003 60.331 25.003 44.928-9.6 60.331-25.003 25.003-36.779 25.003-60.331zM298.667 512c0-23.552-9.6-44.928-25.003-60.331s-36.779-25.003-60.331-25.003-44.928 9.6-60.331 25.003-25.003 36.779-25.003 60.331 9.6 44.928 25.003 60.331 36.779 25.003 60.331 25.003 44.928-9.6 60.331-25.003 25.003-36.779 25.003-60.331z"></path>
            </svg>
          </div>
          <input id='PlayerSlider'
            type="range"
            min="0"
            max={duration}
            value={currentTime}
            className="dth-global-player-web-slider"
            style={{ position: 'absolute', bottom: '10px', width: '90%', left: '5%', color: 'grey', zIndex:'800' }}
            onChange={handleSeek}
          />
        </div>
      </div>
      {isModalOpen && (
        <div className="modal-container" onClick={handleModalOutsideClick}>
          <div className="modal-content">
            <MoreDrawer LiveRadioHandler={LiveRadioHandler} />
          </div>
        </div>
      )}
    </div>
  );
};

DTHGlobalPlayerWeb.defaultProps = {
  imageAlt: 'image',
  imageSrc: '/rosiebot222x166-200h.png',
  imageAlt2: 'image',
  dthtitle: 'The Ham Radio Show Presents Cold Cuts',
  dthsubtitle: 'Best Of GOOM Radio Era',
  imageSrc1: 'https://play.teleporthq.io/static/svg/default-img.svg',
  imageAlt1: 'image',
  PlayerBG: '/6513d74e9b7ca76d70274ee327f06d3b.jpg',
  PlayTPBG: ''
};

DTHGlobalPlayerWeb.propTypes = {
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt2: PropTypes.string,
  dthtitle: PropTypes.string,
  dthsubtitle: PropTypes.string,
  imageSrc1: PropTypes.string,
  imageAlt1: PropTypes.string,
  PlayerBG: PropTypes.string,
  PlayTPBG: PropTypes.string,
};

export default DTHGlobalPlayerWeb;