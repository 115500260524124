import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './test.css'

const Test = (props) => {
  return (
    <div
      className={`test-container1 thq-section-padding ${props.rootClassName} `}
    >
      <div className="test-max-width thq-section-max-width">
        <div className="test-container2">
          <div className="test-container3 thq-card">
            <img
              alt="image"
              src="http://media.silive.com/entertainment_impact_music/photo/goompdfjpg-517e7de77a5f0e73_medium.jpg"
              className="test-image1 thq-img-ratio-1-1"
            />
            <h2>
              {props.step1Title ?? (
                <Fragment>
                  <h2 className="test-text18 thq-heading-2">
                    Staten Island Advance
                  </h2>
                </Fragment>
              )}
            </h2>
            <span>
              {props.step1Description ?? (
                <Fragment>
                  <span className="test-text23 thq-body-small">
                    <span>
                      Finocchiaro’s irreverent new Saturday night talk show —
                      titled “Ham Radio,” an apparent tribute to amateur
                      broadcasters as well as his own antics — broadcasts
                      everything in what he’s dubbed a “mens’ magazine” format.
                      From discussions of bizarre current events, to attaching
                      Tasers to young producers’ nether regions on the air,
                      Uncle Eddie and company are out to push the boundaries of
                      good taste.
                    </span>
                    <br></br>
                    <br></br>
                    <span>
                      “We’re going to do things that nobody has ever done
                      before,” says Finocchiaro. “We’re going to do (expletive)
                      that’s going to (expletive) off a lot of people. Things
                      that make you cringe, and things that make you wet your
                      pants.”
                    </span>
                    <br></br>
                    <br></br>
                    <span>
                      Call them shock jocks, call them completely inappropriate
                      — but Uncle Eddie and his “Ham Radio” cohorts, are making
                      a bid for something big”
                    </span>
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="test-container4 thq-card">
            <img
              alt="image"
              src="https://ynot.b-cdn.net/wp-content/uploads/2018/10/Eddie-Boshido-300x200.jpg"
              className="test-image2 thq-img-ratio-1-1"
            />
            <h2>
              {props.step2Title ?? (
                <Fragment>
                  <h2 className="test-text17 thq-heading-2">YNOT.com</h2>
                </Fragment>
              )}
            </h2>
            <span>
              {props.step2Description ?? (
                <Fragment>
                  <span className="test-text19 thq-body-small">
                    <span>
                      Though he arguably helms the gold standard in unexpurgated
                      radio talk, it should be noted that Howard Stern is not
                      the only person delivering radio with an edge
                    </span>
                    <br></br>
                    <span>
                      Be he spinning around the politics of the day or
                      interviewing adult stars, Eddie Finocchiaro gives forth
                      across his program — “The Ham Radio Show” — with wicked
                      aplomb. One can peruse Ham Radio show’s website to gander
                      at the “Spank Bank of the Week” model, as well as listen
                      to the two-hour program that would pretty much make
                      anybody blush…. Truth: We’re blushing, too.
                    </span>
                  </span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
        <div className="test-container5">
          <div className="test-container6 thq-card">
            <video
              src="https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/bgplayer/EddieSXM-Appearence%20%28Made%20by%20Headliner%29.mp4"
              poster="/14054985_10210497287930817_683960237222517101_n%20(1)-400w.jpg"
              controls="true"
              playsInline="true"
              className="test-video"
            ></video>
            <span>
              {props.text ?? (
                <Fragment>
                  <span className="test-text16">
                    Interview Happened before Uncle Eddie&apos;s Employment at
                    SiriusXM
                  </span>
                </Fragment>
              )}
            </span>
            <h2>
              {props.step3Title ?? (
                <Fragment>
                  <h2 className="test-text31 thq-heading-2">
                    <span>It&apos;s Erik Nagel</span>
                    <br></br>
                  </h2>
                </Fragment>
              )}
            </h2>
          </div>
        </div>
      </div>
    </div>
  )
}

Test.defaultProps = {
  text: undefined,
  rootClassName: '',
  step2Title: undefined,
  step1Title: undefined,
  step2Description: undefined,
  step1Description: undefined,
  step3Title: undefined,
}

Test.propTypes = {
  text: PropTypes.element,
  rootClassName: PropTypes.string,
  step2Title: PropTypes.element,
  step1Title: PropTypes.element,
  step2Description: PropTypes.element,
  step1Description: PropTypes.element,
  step3Title: PropTypes.element,
}

export default Test
