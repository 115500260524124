import React, { useState, useEffect } from 'react';
import axios from 'axios';
import EraSelector from '../components/EraCard'
import { Helmet } from 'react-helmet'
import DTHPWAMenu from '../components/dthpw-menu'
import DTHFooter from '../components/dt-footer'
import './eras.css'


const Eras = (props) => {

  return (
    <div className="eras-container">
      <Helmet>
        <title>Eras - Nick Test</title>
        <meta property="og:title" content="Eras - Nick Test" />
        <meta property="og:description" content="#DemandTheHAM!" />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c8a5e376-1280-4be7-b0d4-c60a65d55836/44b6fe29-9b32-4a55-a492-d8ad2dcd12ae?org_if_sml=1"
        />
      </Helmet>
     
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <EraSelector Erastation="GOOM Radio" />
       
        <EraSelector Erastation="MLR" />
        <EraSelector Erastation="PPR" />
        <EraSelector Erastation="UFRN" />
      </div>
      
    </div>
  )
}

export default Eras
