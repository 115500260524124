import React, { createContext, useEffect, useState, useContext } from 'react';
import { useAudioPlayer } from './Context';
import { 
  auth, createUserWithEmailAndPassword, 
  signInWithPopup, signInWithEmailAndPassword, signOut, 
  onAuthStateChanged, database, FacebookAuthProvider, 
  GoogleAuthProvider, ref, get, set 
} from '../firebase';
const AuthContext = createContext();
const AuthProvider = ({ children }) => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [user, setUser] = useState(null);
  // Destructuring necessary properties from the custom hook useAudioPlayer
  const { setAudioTitle, setAudioSrc, setDuration, setCurrentTime, setIsPlaying, setIsLive } = useAudioPlayer();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsSignedIn(true);
        setUser(user);
        // Fetch user-specific data from Firebase Realtime database
        const userRef = ref(database, `users/${user.uid}`);
        const userSnapshot = await get(userRef);
        if (userSnapshot.exists()) {
          const userData = userSnapshot.val();
          setAudioTitle(userData.audioTitle || '');
          setAudioSrc(userData.audioSrc || '');
          setDuration(userData.duration || 0);
          const activeAudioSyncRef = ref(database, `users/${user.uid}/ActiveAudioSync`);
          const activeAudioSyncSnapshot = await get(activeAudioSyncRef);
          if (activeAudioSyncSnapshot.exists()) {
            const activeAudioSyncData = activeAudioSyncSnapshot.val();
            setAudioTitle(activeAudioSyncData.AudioTitle || '');
            setAudioSrc(activeAudioSyncData.AudioSrc || '');
            setDuration(activeAudioSyncData.Duration || 0);
            setCurrentTime(activeAudioSyncData.currentTime || 0);
          }
        }
      } else {
        setIsSignedIn(false);
        setUser(null);
        // Reset audio player state on sign out
        setAudioTitle('');
        setAudioSrc('');
        setDuration(0);
        setCurrentTime(0);
        setIsPlaying(false);
        setIsLive(false);
      }
    });
    return () => unsubscribe();
  }, [setAudioTitle, setAudioSrc, setDuration, setCurrentTime, setIsPlaying, setIsLive]);
  const signup = async (email, password) => {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user; // Get user object
    // Initialize ActiveAudioSync and favorites for the new user
    const userRef = ref(database, `users/${user.uid}`);
    const activeAudioSyncRef = ref(database, `users/${user.uid}/ActiveAudioSync`);
    const favoritesRef = ref(database, `users/${user.uid}/favorites`);
    await set(userRef, {
      audioTitle: '',
      audioSrc: '',
      duration: 0,
      // Add any other user-specific data if needed
    });
    await set(activeAudioSyncRef, {
      AudioTitle: '',
      Duration: 0,
      AudioImg: '',
      AudioSrc: '',
      currentTime: 0,
    });
    await set(favoritesRef, {
      // Initialize an empty object for future nested objects
    });
    return userCredential;
  };
  const loginWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      setIsSignedIn(true);
      setUser(result.user);
    } catch (error) {
      console.error("Error during Facebook login", error);
    }
  };

  const loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      setIsSignedIn(true);
      setUser(result.user);
    } catch (error) {
      console.error("Error during Google login", error);
    }
  };

  const loginWithEmailAndPassword = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setIsSignedIn(true);
      setUser(userCredential.user);
    } catch (error) {
      console.error("Error during email/password login", error);
    }
  };

  
  const logout = async () => {
    try {
      await signOut(auth);
      setIsSignedIn(false);
      setUser(null);
      // Reset audio player state on logout
      setAudioTitle('The Ham Radio Show');
      setAudioSrc('https://stream.hamradioshow.com');
      setDuration(0);
      setCurrentTime(0);
      setIsPlaying(false);
      setIsLive(true);
    } catch (error) {
      alert('Error logging out: ' + error.message);
    }
  };
  return (
    <AuthContext.Provider value={{ isSignedIn, user, signup, loginWithFacebook,loginWithGoogle, loginWithEmailAndPassword, signInWithEmailAndPassword, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
const useAuth = () => {
  return useContext(AuthContext);
};
export { AuthContext, AuthProvider, useAuth };