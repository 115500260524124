import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import DTHVideo from '../components/DTHVideo'
import SpankBankGallery from '../components/SpankBankGalleryCard'
import './spank-bank-details-page.css'

const SpankBankDetailsPage = (props) => {
  const [title, setTitle] = useState('Loading...')
  const [paragraphs, setParagraphs] = useState([])
  const [galleryItems, setGalleryItems] = useState([])
  const [videoItems, setVideoItems] = useState([])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const postValue = urlParams.get('id')
    const apiUrl = `https://hamradioshowpodcast.com/wp-json/wp/v2/posts/${postValue}`

    axios.get(apiUrl)
      .then(response => {
        if (!response.data) {
          throw new Error('Response data is undefined')
        }

        const { title, content } = response.data

        if (!title || !content || !title.rendered || !content.rendered) {
          throw new Error('Title or content is missing rendered property')
        }

        setTitle(title.rendered)

        const tempDiv = document.createElement('div')
        tempDiv.innerHTML = content.rendered

        const imgs = tempDiv.querySelectorAll('img')
        const videos = tempDiv.querySelectorAll('video')
        const paragraphs = Array.from(tempDiv.querySelectorAll('p'))
          .map(p => p.outerHTML)

        const removeDuplicates = (arr, key) => {
          return [...new Map(arr.map(item => [item[key], item])).values()]
        }

        if (imgs.length > 0) {
          const newGalleryItems = removeDuplicates(Array.from(imgs).map(img => ({
            src: img.src,
            alt: img.alt || 'Gallery image'
          })), 'src')
          setGalleryItems(newGalleryItems)
        }

        if (videos.length > 0) {
          const newVideoItems = removeDuplicates(Array.from(videos).map(video => ({
            src: video.src
          })), 'src')
          setVideoItems(newVideoItems)
        }

        const uniqueParagraphs = [...new Set(paragraphs)]
        setParagraphs(uniqueParagraphs)
      })
      .catch(error => {
        console.error('Error fetching post:', error)
      })
  }, [])

  return (
    <div className="spank-bank-details-page-container">
      <Helmet>
        <title>SpankBank-Details-Page - Nick Test</title>
        <meta property="og:title" content="SpankBank-Details-Page - Nick Test" />
        <meta property="og:description" content="#DemandTheHAM!" />
        <meta property="og:image" content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c8a5e376-1280-4be7-b0d4-c60a65d55836/44b6fe29-9b32-4a55-a492-d8ad2dcd12ae?org_if_sml=1&amp;force_format=original" />
      </Helmet>
      <div className="spank-bank-details-page-feature-card">
        <h2 id="DTH_Spank_Title" className="spank-bank-details-page-text">
          {title}
        </h2>
        {paragraphs.map((paragraph, index) => (
          <span
            key={index}
            id="DTH_Spank_Descript"
            className="spank-bank-details-page-text1"
            style={{ backgroundColor: 'black', margin: '8px', padding: '8px', color: 'white', fontSize: '14px' }}
            dangerouslySetInnerHTML={{ __html: paragraph }}
          />
        ))}
        {galleryItems.length > 0 && (
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
            {galleryItems.map((item, index) => (
              <SpankBankGallery
                key={index}
                imageSrc={item.src}
                imageAlt={item.alt}
                onClickValue="#"
                rootClassName="spank-bank-gallery-root-class-name1"
              />
            ))}
          </div>
        )}
        {videoItems.length > 0 && (
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
            {videoItems.map((item, index) => (
              <DTHVideo
                key={index}
                videoSrc={item.src}
                videoTitle="SpankBank Video"
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default SpankBankDetailsPage