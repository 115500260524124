import React, { useState, useRef, useContext, useEffect } from 'react';
import { useAudioPlayer } from './Context';
import { useAuth } from './Auth';
import { database } from '../firebase';
import { ref, set } from 'firebase/database';
import MoreDrawer from './more-drawer';

// Helper function to remove undefined properties
const removeUndefinedProperties = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== undefined) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
};

const MobilePlayerControl = (props) => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const {
    isPlaying,
    setIsPlaying,
    audioSrc,
    setAudioSrc,
    audioTitle,
    setAudioTitle,
    audioImg,
    setAudioImg,
    isCasting,
    setIsCasting,
    isLive,
    setIsLive,
    duration,
    setDuration,
    TPBGImage,
    SetTPBGImage,
    BGImage,
    SetBGImage,
    audioSubtext,
    setAudioSubtext,
    isFav,
    setIsFav,
  } = useAudioPlayer();

  const audioRef = useRef(null);
  const { user } = useAuth();
  const [currentTime, setCurrentTime] = useState(0);
  const cjs = new Castjs();

  useEffect(() => {
    if (user) {
      const userAudioSyncRef = ref(database, `users/${user.uid}/ActiveAudioSync`);
      const audioSyncData = {
        AudioTitle: audioTitle || '',
        Duration: duration || 0,
        AudioImg: audioImg || '',
        AudioSrc: audioSrc || '',
        currentTime: currentTime || 0,
      };
      const sanitizedAudioSyncData = removeUndefinedProperties(audioSyncData);
      set(userAudioSyncRef, sanitizedAudioSyncData).catch((error) => {
        console.error("Error updating ActiveAudioSync:", error);
      });
    }
  }, [user, audioTitle, duration, audioImg, audioSrc, currentTime]);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
    if (isCasting) {
      isPlaying ? cjs.pause() : cjs.play();
      audioRef.current.muted = true;
    } else {
      audioRef.current.muted = false;
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play().catch(error => {
          console.error("Autoplay not allowed:", error);
          setIsPlaying(false);
        });
      }
    }

    if (user) {
      const userAudioSyncRef = ref(database, `users/${user.uid}/ActiveAudioSync`);
      const audioSyncData = {
        AudioTitle: audioTitle,
        Duration: duration,
        AudioImg: audioImg,
        AudioSrc: audioSrc,
        currentTime: currentTime,
      };

      const sanitizedAudioSyncData = removeUndefinedProperties(audioSyncData);
      if (!isPlaying) {
        set(userAudioSyncRef, sanitizedAudioSyncData).catch((error) => {
          console.error("Error updating ActiveAudioSync:", error);
        });
      }
    }
  };

  const handleSeek = (e) => {
    const seekTime = parseFloat(e.target.value);
    setCurrentTime(seekTime);
    if (isCasting) {
      cjs.seek(seekTime);
    } else {
      audioRef.current.currentTime = seekTime;
      if (isPlaying) {
        audioRef.current.play().catch(error => {
          console.error("Autoplay not allowed:", error);
          setIsPlaying(false);
        });
      }
    }
    if (user && !isPlaying) {
      const userAudioSyncRef = ref(database, `users/${user.uid}/ActiveAudioSync`);
      const audioSyncData = {
        AudioTitle: audioTitle,
        Duration: duration,
        AudioImg: audioImg,
        AudioSrc: audioSrc,
        currentTime: seekTime,
      };
      const sanitizedAudioSyncData = removeUndefinedProperties(audioSyncData);
      set(userAudioSyncRef, sanitizedAudioSyncData).catch((error) => {
        console.error("Error updating ActiveAudioSync:", error);
      });
    }
  };

  const LiveRadioHandler = () => {
    setIsLive(true);
    setAudioSrc("https://Stream.thehamradio.show");
    setAudioTitle("The Ham Radio Show");
    audioRef.current.play();
  };

  const handleLoadedData = () => {
    setDuration(audioRef.current.duration);
  };

  useEffect(() => {
    const handleTimeUpdate = () => {
      if (!isCasting) {
        setCurrentTime(audioRef.current.currentTime);
      }
    };
    if (audioRef.current) {
      audioRef.current.addEventListener("timeupdate", handleTimeUpdate);
      audioRef.current.addEventListener("loadeddata", handleLoadedData);
    }
    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
        audioRef.current.removeEventListener("loadeddata", handleLoadedData);
      }
    };
  }, []);

  useEffect(() => {
    const audio = audioRef.current;
    if (audioSrc && isPlaying) {
      audio.src = audioSrc;
      audio.load();
      if (isCasting) {
        cjs.stop();
        cjs.cast(audioSrc);
      } else {
        audio.play().catch((error) => {
          console.error("Autoplay not allowed:", error);
          setIsPlaying(false);
        });
      }
    }
    audio.muted = isCasting;
  }, [audioSrc, isPlaying, isCasting]);


  useEffect(() => {
    const fetchIcyMetadata = async () => {
      try {
        const response = await fetch('https://streaming.live365.com/listen.pls?station=nativevoice', {
          method: 'GET',
          headers: {
            'Icy-MetaData': '1'
          }
        });
        const metadata = response.headers.get('icy-metaint');

        if (metadata) {
          const metadataInterval = setInterval(async () => {
            const reader = response.body.getReader();
            const result = await reader.read();
            if (result.done) {
              clearInterval(metadataInterval);
            }
            const decoder = new TextDecoder('utf-8');
            const text = decoder.decode(result.value);

            const match = /StreamTitle='([^;]+)';/.exec(text);
            if (match) {
              const [artist, title] = match[1].split(' - ');
              setAudioTitle(title);
              setAudioSubtext(artist);
            }
          }, 60000); // Fetch metadata every 60 seconds
        }
      } catch (error) {
        console.error('Error fetching ICY metadata:', error);
      }
    };

    if (isLive) {
      fetchIcyMetadata();
      const intervalId = setInterval(fetchIcyMetadata, 120000); // Fetch new data every 5 minutes
      return () => clearInterval(intervalId);
    }
  }, [isLive]);

  return (
    <>
      <div className="mobile-controls-container">
        {isPlaying ? (
          <div className="mobile-controls-container1" onClick={handlePlayPause} >
            <svg viewBox="0 0 1024 1024" className="mobile-controls-icon">
              <path d="M768 512L256 512 256 576 768 576 768 512z"></path>
            </svg>
          </div>
        ) : (
          <div className="mobile-controls-container1" onClick={handlePlayPause}>
            <svg viewBox="0 0 1024 1024" className="mobile-controls-icon">
              <path d="M426 704l256-192-256-192v384zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z"></path>
            </svg>
          </div>
        )}
        <div className="mobile-controls-container2">
          <span className="component2-text">{props.audioTitle}</span>
          <span className="component2-text1">{props.subText}</span>
          <audio src={audioSrc} ref={audioRef} hidden></audio>
          <input className="seekbar"
            type="range"
            id="seekbar"
            min="0"
            max={duration}
            value={currentTime}
            onChange={handleSeek}
          />
        </div>
        <div className="mobile-controls-container3" onClick={toggleModal}>
          <svg viewBox="0 0 24 6" className="mobile-controls-icon2" width="12" height="12">
            <circle cx="3" cy="3" r="3"></circle>
            <circle cx="12" cy="3" r="3"></circle>
            <circle cx="21" cy="3" r="3"></circle>
          </svg>
        </div>
      </div>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <MoreDrawer LiveRadioHandler={LiveRadioHandler} />
            <button onClick={toggleModal}>Close</button>
          </div>
        </div>
      )}

      
      <style jsx>
        {`

          .modal {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1001;
          }
          .modal-content {
            background: white;
            border-radius: 4px;
            padding: 20px;
          }

        
          .mobile-controls-container {
            width: 100%;
            height: 400px;
            display: flex;
            position: relative;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 999;
          }

          .seekbar {
            width: 100%;
            height: 12px;
            margin: auto;
            padding: auto;
          }

          .mobile-controls-container1 {
            flex: 0 0 auto;
            width: 100%;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .mobile-controls-icon {
            width: 24px;
            height: 24px;
          }
          .mobile-controls-container2 {
            flex: 0 0 auto;
            width: 100%;
            border: 2px dashed rgba(120, 120, 120, 0.4);
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .mobile-controls-container3 {
            flex: 0 0 auto;
            width: 50%;
            height: 85px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .mobile-controls-icon2 {
            width: 24px;
            height: 24px;
          }
          @media (max-width: 420px) {
            .mobile-controls-container {
              width: 100%;
              height: 102px;
              align-items: center;
              flex-direction: row;
              justify-content: center;
              background-color: #000000;
            }
            .mobile-controls-container1 {
              width: 15%;
            }
            .mobile-controls-icon {
              fill: #d9d9d9;
              width: 100%;
              height: 100%;
            }
            .mobile-controls-container2 {
              width: 60%;
              height: 100%;
              border-width: 0px;
            }
            .mobile-controls-container3 {
              width: 15%;
            }
            .mobile-controls-icon2 {
              fill: #d9d9d9;
              width: 100%;
              height: 100%;
            }
          }
        `}
      </style>
    </>
  );
};

export default MobilePlayerControl;