import React from 'react';

const MobilePlayerPadding = () => {
    return (
        <div style={{ height: '103px', width: '100%' }}>
        
        </div>
    );
};

export default MobilePlayerPadding;
