// src/components/CrewCard.js

import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'; // Import useHistory

const CrewCard = (props) => {
  const history = useHistory(); // Initialize useHistory hook

  const handleOnClick = () => {
    history.push(`/crew?name=${encodeURIComponent(props.crewName)}`);
  };

  return (
    <>
      <div className="crew-card-container" style={{backgroundImage: props.backgroundImage}} onClick={handleOnClick}>
        <span className="crew-card-text">{props.crewName}</span>
        <span className="crew-card-text1">{props.crewPosition}</span>
      </div>
      <style jsx>
        {`
          .crew-card-container {
            width: 250px;
            height: 250px;
            display: flex;
            position: relative;
            align-items: flex-start;
            border-radius: var(--dl-radius-radius-radius4);
            flex-direction: column;
            background-size: cover;
            justify-content: flex-end;
            background-image: linear-gradient(
                270deg,
                rgb(0, 0, 0) 0%,
                rgba(44, 62, 80, 0.25) 57%
              ),
              url('/eddie%20tounge-300h.png'),
              linear-gradient(90deg, rgb(189, 195, 199) 0%, rgb(0, 0, 0) 0%);
            background-repeat: no-repeat, , repeatrepeat;
            background-position: top left, top, top left;
            border-top-left-radius: var(--dl-radius-radius-radius4);
            border-top-right-radius: var(--dl-radius-radius-radius4);
            border-bottom-left-radius: var(--dl-radius-radius-radius4);
            border-bottom-right-radius: var(--dl-radius-radius-radius4);
          }
          .crew-card-text {
            top: 153px;
            left: -2px;
            padding: var(--dl-space-space-halfunit);
            position: absolute;
            font-size: 18px;
            align-self: center;
            font-style: normal;
            font-weight: 700;
            background-color: rgba(217, 217, 217, 0.71);
          }
          .crew-card-text1 {
            top: 189px;
            fill: var(--dl-color-theme-secondary1);
            left: 5px;
            color: var(--dl-color-theme-secondary1);
            margin: auto;
            padding: var(--dl-space-space-halfunit);
            position: absolute;
            background-color: #080808;
          }
        `}
      </style>
    </>
  )
}

CrewCard.defaultProps = {
  crewName: 'Uncle Eddie',
  crewPosition: 'Head A**hole in Charge',
  backgroundImage: ''
}

CrewCard.propTypes = {
  crewName: PropTypes.string,
  crewPosition: PropTypes.string,
  backgroundImage: PropTypes.string,
}

export default CrewCard