import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './style.css';
import NewsDetailsPage from './views/news-details-page';
import SpeicalFeature from './views/speical-feature';
import SpankBank from './views/spank-bank';
import EpisodeDetailsPage from './views/episode-details-page';
import Page from './views/page';
import Home from './views/home';
import Eras from './views/eras';
import CrewPage from './views/crew-page';
import Error from './views/error';
import CrewProfilePage from './views/CrewProfile-Page';
import Help from './views/help';
import Search from './views/search';
import About from './views/about';
import EraPostSelelctionTemplate from './views/DTHEraSelection';
import MobilePlayerControl from './components/MobileControls';
import SpankBankDetailsPage from './views/spank-bank-details-page';
import SpankBankSelection from './views/spank-bank-selection';
import SigninPage from './views/SigninPage';
import SignupPage from './views/SignupPage';
import PlayListPage from './views/PlayList-Page';

import DTHGlobalPlayerWeb from './components/dth-global-player-web';
import DTHPWAMobilePlayer from './components/dthpw-mobile-player';
import { AudioPlayerProvider } from './components/Context';
import DTHPWAMenu from './components/dthpw-menu';
import { AuthProvider } from './components/Auth'; // Import the AuthProvider
import DTHROOF from './components/DTHROOF';
import ProtectedRoute from './components/ProtectedRoute';
import MobilePlayerPadding from './components/mobileplayerpadding';

import ReactGA4 from 'react-ga4';
ReactGA4.initialize('G-HG5WVCGKM6');


const App = () => {
  const screenWidth = window.innerWidth;
  const isMobile = screenWidth <= 550;

  
  return (
    <Router>
      <AudioPlayerProvider>
        <AuthProvider>
          {isMobile ? (
            <>
              <DTHROOF />
              <DTHPWAMobilePlayer />
              <DTHPWAMenu />
              
              <MobilePlayerControl />
              
            
              
            </>
          ) : (
            <>
              <DTHROOF />
              <DTHGlobalPlayerWeb />
              <DTHPWAMenu />
            </>
          )}

          <div>
            <Route component={NewsDetailsPage} exact path="/News" />
            <Route component={SpeicalFeature} exact path="/speical-feature" />
            <Route component={EpisodeDetailsPage} exact path="/episode" />
            <Route component={Page} exact path="/page" />
            <Route component={Home} exact path="/" />
            <Route component={Eras} exact path="/eras" />
            <Route component={CrewPage} exact path="/crew-page" />
            <Route component={Error} path="error" />
            <Route component={CrewProfilePage} exact path="/Crew" />
            <Route component={Help} exact path="/help" />
  <ProtectedRoute component={SpankBankDetailsPage} exact path="/SpankBank/Post" />
  <ProtectedRoute component={SpankBankSelection} exact path="/SpankBank" />
            <Route component={Search} exact path="/search" />
            <Route component={About} exact path="/about" />
            <Route component={EraPostSelelctionTemplate} exact path="/era" />
            <Route component={SigninPage} exact path="/signin" />
            <Route component={SignupPage} exact path="/signup" />
            <Route component={PlayListPage} exact path="/user/favorites" />
          </div>
          {isMobile && <MobilePlayerPadding />}
        </AuthProvider>
      </AudioPlayerProvider>
    </Router>
  );
};

ReactDOM.render(
  <AudioPlayerProvider>
    <AuthProvider> {/* Wrap App with AuthProvider */}
      <App />
      
    </AuthProvider>
  </AudioPlayerProvider>,
  document.getElementById('app')
);