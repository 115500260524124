import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import DTHPWAMenu from '../components/dthpw-menu';
import './search.css';
import axios from 'axios';
import DTHEPICARD from '../components/dthepicard';
import { SearchContext } from '../components/Context';



const Search = (props) => {
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const term = urlSearchParams.get('term');

  const [episodes, setEpisodes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const encodedTerm = encodeURIComponent(term); // Encode the term for full text search
        const response = await axios.get(`https://wizzvzmqudvvbgzkrhrt.supabase.co/rest/v1/Episodes?description=plfts.${encodedTerm}`, {
          headers: {
            'apiKey': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Indpenp2em1xdWR2dmJnemtyaHJ0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODg0OTkyNzIsImV4cCI6MjAwNDA3NTI3Mn0.MsWGlJzP0wyx4MrwTo5RZpzlSAxgypPG41V_w-cRnOI'
          }
        });
        setEpisodes(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [term]);

  return (
    <div className="search-container">
      <Helmet>
        <title>Search - {term}</title>
        <meta property="og:title" content="Search - Nick Test" />
        <meta property="og:description" content="#DemandTheHAM!" />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c8a5e376-1280-4be7-b0d4-c60a65d55836/44b6fe29-9b32-4a55-a492-d8ad2dcd12ae?org_if_sml=1"
        />
      </Helmet>

      <div className="search-blog">
        <div className="search-container1" />
        <div className="search-container2" />
      </div>
      <h1> Search: {term}</h1>
      <br />

      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
        {episodes.map((episode) => (
          <DTHEPICARD
            key={episode.objectID}
            CardTitle={episode.title}
            EpisodeDescript={episode.description}
            url={episode.EpisodeUrl}
            BgImage={episode.backgroundImage || 'https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/HAMFallBack.jpg'}
          />
        ))}
      </div>

      
    </div>
  );
};

export default Search;
