import React, { useContext, useEffect,useState } from 'react';
import { useAudioPlayer } from '../components/Context';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import './episode-details-page.css';



const EpisodeDetails = (props) => {

  const {isPlaying,
          setIsPlaying,
        audioTitle,
          setAudioTitle,setAudioSrc}=useAudioPlayer();
  

  const [data, setData] = useState({});

    const { handleOnClick, isLive,
            setIsLive, } = useAudioPlayer();
  const handleClick = () => {
    if (audioTitle !== (data.length > 0 && data[0]?.title)) {
      if (isPlaying) {
        setIsPlaying(false); // Stop the audio
        setAudioSrc(''); // Unload the current audio source

        // Set the new audio source for the clicked item after a slight delay to ensure state updates
        setTimeout(() => {
          setAudioSrc(data[0]?.EpisodeUrl);
          setAudioTitle(data[0]?.title);
          setIsPlaying(true); // Play the new audio automatically
        }, 100); // Adjust the delay time as necessary
      } else {
        // Set the new audio source for the clicked item directly
        setAudioSrc(data[0]?.EpisodeUrl);
        setAudioTitle(data[0]?.title);
        setIsPlaying(true); // Play the new audio automatically
      }
    } else {
      // Toggle play/pause if the audioTitle matches the current title
      setIsPlaying(!isPlaying);
    }
  };

    useEffect(() => {
    const title = new URLSearchParams(window.location.search).get('title');
    const encodedTitle = encodeURIComponent(title);

    axios.get(`https://wizzvzmqudvvbgzkrhrt.supabase.co/rest/v1/Episodes?title=plfts.${encodedTitle}`, {
      headers: {
        'apiKey': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Indpenp2em1xdWR2dmJnemtyaHJ0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODg0OTkyNzIsImV4cCI6MjAwNDA3NTI3Mn0.MsWGlJzP0wyx4MrwTo5RZpzlSAxgypPG41V_w-cRnOI',
        'x-DTH-Platform-Id': 'WebApp',
        'x-Request-Type':'Episode-Display',
      }
    })
      .then(response => {
        setData(response.data);
        console.log(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);


  
	const handleShareCastClick = () => {
		alert("Coming Soon");
	};
  
  return (
    <div className="episode-details-container">
      <Helmet>
        <title>DemandTheHAM </title>
        <meta property="og:title" content="Episode-Details - Nick Test" />
        <meta property="og:description" content="#DemandTheHAM!" />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c8a5e376-1280-4be7-b0d4-c60a65d55836/44b6fe29-9b32-4a55-a492-d8ad2dcd12ae?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <div className="episode-details-container1"></div>
      <div className="episode-details-container2" style={{
          backgroundImage: `url(${data.length > 0 && data[0]?.PodcastImage ? data[0].PodcastImage : 'https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/DTHCrew/HAM%20Radio%20GOOM%20STUDIO.png'})`,
        }}>
      
        {isPlaying && audioTitle === (data.length > 0 && data[0]?.title) ? (
          <svg viewBox="0 0 1024 1024" className="episode-details-icon" onClick={handleClick}>
            <path d="M768 512L256 512 256 576 768 576 768 512z"></path>
          </svg>
        ) : (
          <svg viewBox="0 0 1024 1024" className="episode-details-icon" onClick={handleClick}>
            <path d="M426 704l256-192-256-192v384zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z"></path>
          </svg>
        )}
        <div className="episode-details-container3">
          <span id="MobileTitle" className="episode-details-text">
            {data.length > 0 && data[0]?.title}
          </span>
          <span id="EpisodeEraSubtitle" className="episode-details-text1">
            <span> {data.length > 0 && data[0]?.subtext || 'The Most Dangerous Radio Today...and perhaps even tomorrow'}</span>
            <br></br>
          </span>
        </div>
      </div>
      <div className="episode-details-container4">
        <span id="Epi-Detail-Descript" className="episode-details-text4">
          {data.length > 0 && data[0]?.description} 
        </span>
      </div>
      <div className="episode-details-container5"onClick={handleShareCastClick}>
        <svg viewBox="0 0 1024 1024" className="episode-details-icon2" id="Cast">
          <path d="M42 426q194 0 332 138t138 332h-86q0-160-113-272t-271-112v-86zM42 598q124 0 212 87t88 211h-86q0-88-63-151t-151-63v-84zM42 768q52 0 90 38t38 90h-128v-128zM896 128q34 0 60 26t26 60v596q0 34-26 60t-60 26h-298v-86h298v-596h-768v128h-86v-128q0-34 26-60t60-26h768z"></path>
        </svg>
        <svg
          viewBox="0 0 954.2948571428572 1024"
          className="episode-details-icon4"
          id="Share"
        >
          <path d="M804.571 565.143v148c0 90.857-73.714 164.571-164.571 164.571h-475.429c-90.857 0-164.571-73.714-164.571-164.571v-475.429c0-90.857 73.714-164.571 164.571-164.571h145.714c9.714 0 18.286 8 18.286 18.286 0 9.143-6.286 16.571-14.857 18.286-28.571 9.714-54.286 21.143-76 34.286-2.857 1.143-5.714 2.286-9.143 2.286h-64c-50.286 0-91.429 41.143-91.429 91.429v475.429c0 50.286 41.143 91.429 91.429 91.429h475.429c50.286 0 91.429-41.143 91.429-91.429v-122.286c0-6.857 4-13.143 10.286-16.571 11.429-5.143 21.714-12.571 30.857-21.143 5.143-5.143 13.143-7.429 20-4.571s12 9.143 12 16.571zM940 281.714l-219.429 219.429c-6.857 7.429-16 10.857-25.714 10.857-4.571 0-9.714-1.143-14.286-2.857-13.143-5.714-22.286-18.857-22.286-33.714v-109.714h-91.429c-125.714 0-205.714 24-250.286 74.857-46.286 53.143-60 138.857-42.286 270.286 1.143 8-4 16-11.429 19.429-2.286 0.571-4.571 1.143-6.857 1.143-5.714 0-11.429-2.857-14.857-7.429-4-5.714-94.857-134.286-94.857-248.571 0-153.143 48-329.143 420.571-329.143h91.429v-109.714c0-14.857 9.143-28 22.286-33.714 4.571-1.714 9.714-2.857 14.286-2.857 9.714 0 18.857 4 25.714 10.857l219.429 219.429c14.286 14.286 14.286 37.143 0 51.429z"></path>
        </svg>
      </div>

      
    </div>
  )
}

export default EpisodeDetails

