import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import Test from './test'
import './abouttimeline.css'

const Abouttimeline = (props) => {
  return (
    <div className={`abouttimeline-container1 ${props.rootClassName} `}>
      <div className="abouttimeline-container2">
        <span>
          {props.text ?? (
            <Fragment>
              <span className="abouttimeline-text30">
                It all started at Connecticut school of broadcasting “in an
                empty room” and has developed into the most dangerous radio show
                on the internet, armed with weapons of mass discussion. Tackling
                the hot button topics or chatting it up with the biggest stars
                (and even the smallest ones too!) in the entertainment industry
                or having a vuvuzela go where its never been before! It’s raw,
                it’s edgy, It’s completely Uncensored, it’s The Ham Radio Show.
              </span>
            </Fragment>
          )}
        </span>
      </div>
      <div className="abouttimeline-container3">
        <h1>
          {props.heading1 ?? (
            <Fragment>
              <h1 className="abouttimeline-text31">
                But Of Course, Don&apos;t Take Our Word For It....
              </h1>
            </Fragment>
          )}
        </h1>
      </div>
      <Test
        text={
          <Fragment>
            <span className="abouttimeline-text12">
              Interview Happened before Uncle Eddie&apos;s Employment at
              SiriusXM
            </span>
          </Fragment>
        }
        step1Title={
          <Fragment>
            <span className="abouttimeline-text13 thq-heading-2">
              Staten Island Advance
            </span>
          </Fragment>
        }
        step2Title={
          <Fragment>
            <span className="abouttimeline-text14 thq-heading-2">YNOT.com</span>
          </Fragment>
        }
        step3Title={
          <Fragment>
            <span className="abouttimeline-text15 thq-heading-2">
              <span>It&apos;s Erik Nagel</span>
              <br></br>
            </span>
          </Fragment>
        }
        rootClassName="testroot-class-name"
        step1Description={
          <Fragment>
            <span className="abouttimeline-text18 thq-body-small">
              <span>
                Finocchiaro’s irreverent new Saturday night talk show — titled
                “Ham Radio,” an apparent tribute to amateur broadcasters as well
                as his own antics — broadcasts everything in what he’s dubbed a
                “mens’ magazine” format. From discussions of bizarre current
                events, to attaching Tasers to young producers’ nether regions
                on the air, Uncle Eddie and company are out to push the
                boundaries of good taste.
              </span>
              <br></br>
              <br></br>
              <span>
                “We’re going to do things that nobody has ever done before,”
                says Finocchiaro. “We’re going to do (expletive) that’s going to
                (expletive) off a lot of people. Things that make you cringe,
                and things that make you wet your pants.”
              </span>
              <br></br>
              <br></br>
              <span>
                Call them shock jocks, call them completely inappropriate — but
                Uncle Eddie and his “Ham Radio” cohorts, are making a bid for
                something big”
              </span>
            </span>
          </Fragment>
        }
        step2Description={
          <Fragment>
            <span className="abouttimeline-text26 thq-body-small">
              <span>
                Though he arguably helms the gold standard in unexpurgated radio
                talk, it should be noted that Howard Stern is not the only
                person delivering radio with an edge
              </span>
              <br></br>
              <span>
                Be he spinning around the politics of the day or interviewing
                adult stars, Eddie Finocchiaro gives forth across his program —
                “The Ham Radio Show” — with wicked aplomb. One can peruse Ham
                Radio show’s website to gander at the “Spank Bank of the Week”
                model, as well as listen to the two-hour program that would
                pretty much make anybody blush…. Truth: We’re blushing, too.
              </span>
            </span>
          </Fragment>
        }
      ></Test>
    </div>
  )
}

Abouttimeline.defaultProps = {
  text: undefined,
  heading1: undefined,
  rootClassName: '',
}

Abouttimeline.propTypes = {
  text: PropTypes.element,
  heading1: PropTypes.element,
  rootClassName: PropTypes.string,
}

export default Abouttimeline
