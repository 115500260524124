import React from 'react'

import PropTypes from 'prop-types'

const DTHVideo = (props) => {
  return (
    <>
      <div className="dth-video-container">
        <h1 className="dth-video-text">{props.videoTitle}</h1>
        <video
          src={props.videoSrc}
          poster="https://play.teleporthq.io/static/svg/videoposter.svg"
          controls="true"
          preload="auto"
          className="dth-video-video"
        ></video>
      </div>
      <style jsx>
        {`
          .dth-video-container {
            width: 100%;
            height: 400px;
            margin: var(--dl-space-space-halfunit);
            display: flex;
            position: relative;
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;
            background-color: #3a3a3a;
          }
          .dth-video-text {
            margin: var(--dl-space-space-halfunit);
            padding: var(--dl-space-space-halfunit);
            font-style: normal;
            font-weight: 700;
            background-color: #ffffff;
          }
          .dth-video-video {
            width: 100%;
            height: 312px;
            margin: var(--dl-space-space-halfunit);
          }
          @media (max-width: 420px) {
            .dth-video-container {
              background-color: #252525;
            }
          }
        `}
      </style>
    </>
  )
}

DTHVideo.defaultProps = {
  videoTitle: 'Video Title',
  videoSrc: '',
}

DTHVideo.propTypes = {
  videoTitle: PropTypes.string,
  videoSrc: PropTypes.string,
}

export default DTHVideo
