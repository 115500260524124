import React from 'react'

import PropTypes from 'prop-types'

const DTHCrewHeader = (props) => {
  return (
    <>
      <div id="BGImage" className="dth-crew-header-container" style={{ backgroundImage: `url(${props.bgImageSrc || '/gahamradio-800w.png'})` }}>
        <div className="dth-crew-header-container1"></div>
        <div id="TPImage" className="dth-crew-header-container2" style={{ backgroundImage: `url(${props.tpImageSrc})` }}></div>
        <div className="dth-crew-header-container3"></div>
      </div>
      <style jsx>
        {`
          .dth-crew-header-container {
            width: 100%;
            height: 300px;
            display: flex;
            position: relative;
            align-items: center;
            flex-direction: row;
            background-size: cover;
            justify-content: center;
            background-image: url('/eddie-dth-profile-1500w.png');
            background-repeat: no-repeat;
            background-position: center;
          }
          .dth-crew-header-container1 {
            flex: 0 0 auto;
            width: auto;
            height: 100%;
            display: flex;
            align-self: center;
            align-items: flex-start;
            border-color: rgba(120, 120, 120, 0.4);
            border-style: dashed;
            border-width: 0px;
          }
          .dth-crew-header-container2 {
            flex: 1;
            width: var(--dl-size-size-xlarge);
            height: 100%;
            display: flex;
            align-self: center;
            align-items: flex-end;
            border-color: rgba(120, 120, 120, 0.4);
            border-style: dashed;
            border-width: 0px;
            background-size: auto;
            justify-content: center;
            background-image: url('/eddie%20tounge-300h.png');
            background-position: bottom;
            background-repeat: no-repeat;
          }
          .dth-crew-header-container3 {
            flex: 0 0 auto;
            width: auto;
            height: 100%;
            display: flex;
            align-self: center;
            align-items: flex-start;
            border-color: rgba(120, 120, 120, 0.4);
            border-style: dashed;
            border-width: 0px;
          }
          .dth-crew-header-text {
            margin: var(--dl-space-space-unit);
            padding: var(--dl-space-space-halfunit);
            background-color: #d9d9d9;
          }
          .dth-crew-header-text1 {
            margin: var(--dl-space-space-unit);
            padding: var(--dl-space-space-halfunit);
            background-color: #ffffff;
          }
          .dth-crew-header-text2 {
            margin: var(--dl-space-space-unit);
            padding: var(--dl-space-space-halfunit);
            background-color: #d9d9d9;
          }
          .dth-crew-header-text3 {
            margin: var(--dl-space-space-unit);
            padding: var(--dl-space-space-halfunit);
          }
          @media (max-width: 420px) {
            .dth-crew-header-container {
              flex-direction: row;
            }
          }
        `}
      </style>
    </>
  )
}

DTHCrewHeader.defaultProps = {
    tpImageSrc: 'https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/DTHCrew/Uncle%20Eddie%20Profile%20Picture.png',
    bgImageSrc: 'https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/DTHCrew/HAM%20Radio%20GOOM%20STUDIO.png',
  crewName: undefined,
  crewPosition: undefined,
  crewName1: undefined,
  text: undefined,
  imageSrc1: 'https://play.teleporthq.io/static/svg/default-img.svg',
  imageAlt: 'image',
  text1: undefined,
}

DTHCrewHeader.propTypes = {
    tpImageSrc: PropTypes.string,
    bgImageSrc: PropTypes.string,
  crewName: PropTypes.string,
  crewPosition: PropTypes.element,
  crewName1: PropTypes.element,
  text: PropTypes.element,
  imageSrc1: PropTypes.string,
  imageAlt: PropTypes.string,
  text1: PropTypes.element,
}
export default DTHCrewHeader
