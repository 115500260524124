import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useAuth } from '../components/Auth';
import { Link, useHistory } from 'react-router-dom'; // Import useHistory
const SignupPage = (props) => {
  const { signup, loginWithFacebook, loginWithGoogle } = useAuth();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const handleEmailChange = (event) => setEmail(event.target.value);
  const handlePasswordChange = (event) => setPassword(event.target.value);
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await signup(email, password);
      alert("Nice!! Your All Set to DemandTheHam! Now Login to your account");
      history.push("/signin"); // Redirect to signin page
    } catch (error) {
      console.error("Error signing up:", error);
      alert("Error signing up: " + error.message);
    }
  };
  const handleFacebookLogin = async () => {
    try {
      await loginWithFacebook();
      history.push("/"); // Redirect to home page
    } catch (error) {
      console.error("Error during Facebook login", error);
      alert("Error during Facebook login: " + error.message);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await loginWithGoogle();
      history.push("/"); // Redirect to home page
    } catch (error) {
      console.error("Error during Google login", error);
      alert("Error during Google login: " + error.message);
    }
  };
  return (
    <>
      <div className="signup-page-container">
        <Helmet>
          <title>SignupPage - Nick Test</title>
          <meta property="og:title" content="SignupPage - Nick Test" />
          <meta property="og:description" content="#DemandTheHAM!" />
          <meta
            property="og:image"
            content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c8a5e376-1280-4be7-b0d4-c60a65d55836/44b6fe29-9b32-4a55-a492-d8ad2dcd12ae?org_if_sml=1&amp;force_format=original"
          />
        </Helmet>
        <div className="signup-page-container1">
          <img
            alt="SignUp Image"
            src="https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/DTHCrew/HAM%20Radio%20GOOM%20STUDIO.png"
            className="signup-page-sign-up-image thq-img-ratio-16-9"
          />
          <div className="signup-page-container2"></div>
          <div className="signup-page-form-root thq-section-padding">
            <div className="signup-page-form thq-section-padding">
              <div className="signup-page-title-root">
                <h2>Sign Up</h2>
                <p className="thq-body-large signup-page-text1">
                  Already have an account? <Link to="/signin">Sign in</Link>
                </p>
              </div>
              <form className="signup-page-form1" onSubmit={handleSubmit}>
                <div className="signup-page-email">
                  <label
                    htmlFor="thq-sign-up-10-email"
                    className="thq-body-large"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="thq-sign-up-10-email"
                    required="true"
                    placeholder="Email address"
                    className="signup-page-textinput thq-input thq-body-large"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </div>
                <div className="signup-page-password">
                  <div className="signup-page-textfield">
                    <div className="signup-page-container3">
                      <label
                        htmlFor="thq-sign-up-10-password"
                        className="thq-body-large"
                      >
                        Password
                      </label>
                      <div className="signup-page-hide-password">
                        <svg
                          viewBox="0 0 1024 1024"
                          className="signup-page-icon"
                        >
                          <path d="M317.143 762.857l44.571-80.571c-66.286-48-105.714-125.143-105.714-206.857 0-45.143 12-89.714 34.857-128.571-89.143 45.714-163.429 117.714-217.714 201.714 59.429 92 143.429 169.143 244 214.286zM539.429 329.143c0-14.857-12.571-27.429-27.429-27.429-95.429 0-173.714 78.286-173.714 173.714 0 14.857 12.571 27.429 27.429 27.429s27.429-12.571 27.429-27.429c0-65.714 53.714-118.857 118.857-118.857 14.857 0 27.429-12.571 27.429-27.429zM746.857 220c0 1.143 0 4-0.571 5.143-120.571 215.429-240 432-360.571 647.429l-28 50.857c-3.429 5.714-9.714 9.143-16 9.143-10.286 0-64.571-33.143-76.571-40-5.714-3.429-9.143-9.143-9.143-16 0-9.143 19.429-40 25.143-49.714-110.857-50.286-204-136-269.714-238.857-7.429-11.429-11.429-25.143-11.429-39.429 0-13.714 4-28 11.429-39.429 113.143-173.714 289.714-289.714 500.571-289.714 34.286 0 69.143 3.429 102.857 9.714l30.857-55.429c3.429-5.714 9.143-9.143 16-9.143 10.286 0 64 33.143 76 40 5.714 3.429 9.143 9.143 9.143 15.429zM768 475.429c0 106.286-65.714 201.143-164.571 238.857l160-286.857c2.857 16 4.571 32 4.571 48zM1024 548.571c0 14.857-4 26.857-11.429 39.429-17.714 29.143-40 57.143-62.286 82.857-112 128.571-266.286 206.857-438.286 206.857l42.286-75.429c166.286-14.286 307.429-115.429 396.571-253.714-42.286-65.714-96.571-123.429-161.143-168l36-64c70.857 47.429 142.286 118.857 186.857 192.571 7.429 12.571 11.429 24.571 11.429 39.429z"></path>
                        </svg>
                        <span className="thq-body-small">Hide</span>
                      </div>
                    </div>
                    <input
                      type="password"
                      id="thq-sign-up-10-password"
                      required="true"
                      placeholder="Password"
                      className="signup-page-textinput1 thq-input thq-body-large"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="signup-page-button thq-button-filled"
                >
                  <span>Submit</span>
                </button>
              </form>
              <div className="signup-page-divider">
                <div className="signup-page-divider1"></div>
                <p className="thq-body-large">Or continue with</p>
                <div className="signup-page-divider2"></div>
              </div>
              <div className="signup-page-container4">
                <button
                  aria-label="Sign up with Facebook"
                  className="signup-page-button1 thq-button-outline"
                  onClick={handleFacebookLogin}
                >
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="signup-page-icon2"
                  >
                    <path d="M713.143 73.143c90.857 0 164.571 73.714 164.571 164.571v548.571c0 90.857-73.714 164.571-164.571 164.571h-107.429v-340h113.714l17.143-132.571h-130.857v-84.571c0-38.286 10.286-64 65.714-64l69.714-0.571v-118.286c-12-1.714-53.714-5.143-101.714-5.143-101.143 0-170.857 61.714-170.857 174.857v97.714h-114.286v132.571h114.286v340h-304c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571z"></path>
                  </svg>
                </button>
                <button
                  aria-label="Sign up with Google"
                  className="signup-page-button2 thq-button-outline"
                  onClick={handleGoogleLogin}
                >
                  <svg
                    viewBox="0 0 1024 1024"
                    className="signup-page-icon4"
                  >
                    <path d="M522.2 438.8v175.6h290.4c-11.8 75.4-87.8 220.8-290.4 220.8-174.8 0-317.4-144.8-317.4-323.2s142.6-323.2 317.4-323.2c99.4 0 166 42.4 204 79l139-133.8c-89.2-83.6-204.8-134-343-134-283 0-512 229-512 512s229 512 512 512c295.4 0 491.6-207.8 491.6-500.2 0-33.6-3.6-59.2-8-84.8l-483.6-0.2z"></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .signup-page-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.signup-page-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.signup-page-sign-up-image {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  position: absolute;
}
.signup-page-container2 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  bottom: 0px;
  height: 100%;
  display: flex;
  z-index: 10;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: rgba(0,0,0,0.4);
}
.signup-page-form-root {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 100;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.signup-page-form {
  gap: var(--dl-space-space-twounits);
  width: auto;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: flex-end;
  background-color: var(--dl-color-theme-neutral-light);
}
.signup-page-title-root {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.signup-page-form1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.signup-page-email {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.signup-page-textinput {
  width: 100%;
  border-color: var(--dl-color-theme-primary1);
  background-color: transparent;
}
.signup-page-password {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.signup-page-textfield {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.signup-page-container3 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.signup-page-hide-password {
  gap: var(--dl-space-space-halfunit);
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 1;
  justify-content: center;
}
.signup-page-icon {
  fill: var(--dl-color-theme-neutral-dark);
  width: 24px;
  height: 24px;
}
.signup-page-textinput1 {
  width: 100%;
  border-color: var(--dl-color-theme-primary1);
  background-color: transparent;
}
.signup-page-button {
  width: 100%;
}
.signup-page-divider {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.signup-page-divider1 {
  flex: 1;
  width: var(--dl-size-size-medium);
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-theme-primary1);
}
.signup-page-divider2 {
  flex: 1;
  width: var(--dl-size-size-medium);
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-theme-primary1);
}
.signup-page-container4 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.signup-page-button1 {
  gap: var(--dl-space-space-halfunit);
  width: auto;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-primary1);
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
}
.signup-page-icon2 {
  width: 24px;
  height: 24px;
}
.signup-page-button2 {
  gap: var(--dl-space-space-halfunit);
  width: auto;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-primary1);
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
}
.signup-page-icon4 {
  width: 24px;
  height: 24px;
}
@media(max-width: 479px) {
  .signup-page-form {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .signup-page-text1 {
    text-align: center;
  }
  .signup-page-button {
    width: 100%;
  }
  .signup-page-button1 {
    width: 100%;
  }
  .signup-page-button2 {
    width: 100%;
  }
}



        `}
      </style>
    </>
  )
}
SignupPage.propTypes = {
  signup: PropTypes.func,
  loginWithFacebook: PropTypes.func,
  loginWithGoogle: PropTypes.func,
}

export default SignupPage
