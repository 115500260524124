import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import DTHEPICARD from '../components/dthepicard';
import EraSelector from '../components/EraCard';
import NewsCard from '../components/NewsCard';
import './home.css';


const Home = (props) => {
  const [episodes, setEpisodes] = useState([]);
  const [news, setNews] = useState([]);

  useEffect(() => {
    const fetchEpisodes = async () => {
      try {
        const response = await axios.get(
          'https://wizzvzmqudvvbgzkrhrt.supabase.co/rest/v1/Episodes?order=pubdate.desc&limit=5',
          {
            headers: {
              apiKey:
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Indpenp2em1xdWR2dmJnemtyaHJ0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODg0OTkyNzIsImV4cCI6MjAwNDA3NTI3Mn0.MsWGlJzP0wyx4MrwTo5RZpzlSAxgypPG41V_w-cRnOI',
            },
          }
        );
        setEpisodes(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchNews = async () => {
      try {
        const response = await axios.get(
          'https://hamradioshowpodcast.com/wp-json/wp/v2/posts?categories=24,26,27,28,30'
        );
        setNews(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchEpisodes();
    fetchNews();
  }, []);

  return (
    
    <>
      <div className="home-container">
        <Helmet>
          <title>Nick Test</title>
          <meta property="og:title" content="Nick Test" />
          <meta property="og:description" content="#DemandTheHAM!" />
          <meta
            property="og:image"
            content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c8a5e376-1280-4be7-b0d4-c60a65d55836/44b6fe29-9b32-4a55-a492-d8ad2dcd12ae?org_if_sml=1&amp;force_format=original"
          />
        </Helmet>
        
      
    
        <div id="WS" className="home-container1"></div>
         <div id="SponsorTitle" className=".sponsor-block">
           <h1>Presented by an awesome sponsor that will appear here soon!</h1>
           
         </div>
        <span className="home-text">
          <span>Latest Episodes</span>
          <br></br>
        </span>
        <div id="DTH_Episodes_Gird" className="home-container2" style={{ textAlign: 'center' }}>
          {episodes
            .sort((a, b) => new Date(b.pubdate) - new Date(a.pubdate))
            .slice(0, 5)
            .map((episode) => (
              <div key={episode.objectID} className="Episode-item" style={{ margin: '8px', display: 'flex', justifyContent: 'center' }}>
                <DTHEPICARD
                  CardTitle={episode.title}
                  EpisodeDescript={episode.description}
                  episode={episode}
                  url={episode['EpisodeUrl']}
                  BgImage={episode.PodcastImage ? episode.PodcastImage : 'https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/HAMFallBack.jpg'}
                />
              </div>
            ))}
        </div>
        <div id="Era_Grid" className="home-container3">
          <div className="eracontainer" style={{ display: 'flex', flexWrap: 'wrap' }}>
            <EraSelector Erastation="GOOM Radio" />
            <EraSelector Erastation="MLR" />
            <EraSelector Erastation="PPR" />
            <EraSelector Erastation="UFRN" />
          </div>
        </div>
        <span className="home-text3">
          <span>Direct From The Bunker</span>
          <br></br>
        </span>
        <div className="home-container4">
          {news.map((item) => (
            <div style={{ margin: '8px' }}>
              <NewsCard
                key={item.id}
                Title={item.title.rendered}
                Subtext={item.excerpt.rendered.replace(/(<([^>]+)>)/gi, "")}
                PostID= {item.id}
              />
            </div>
          ))}
        </div>
        
      </div>
    </>
  );
};

export default Home;