import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './news-details-page.css';

 

const NewsDetailsPage = (props) => {
  const location = useLocation(); // Get location object
  const query = new URLSearchParams(location.search); // Parse query parameters
  const id = query.get('id'); // Extract 'id' parameter
  const [newsDetails, setNewsDetails] = useState(null); // State to hold the fetched news details

  useEffect(() => {
    // Log the id to ensure it's correctly extracted
    console.log(`Extracted id: ${id}`);

    // Function to fetch news details using Axios
    const fetchNewsDetails = async () => {
      try {
        // Ensure id is defined before making the API call
        if (id) {
          const response = await axios.get(`https://hamradioshowpodcast.com/wp-json/wp/v2/posts/${id}`);
          setNewsDetails(response.data);
        } else {
          console.error("ID parameter is undefined.");
        }
      } catch (error) {
        console.error("Error fetching news details", error);
      }
    };

    fetchNewsDetails();
  }, [id]); // Run the effect when id changes

  if (!newsDetails) {
    return (
      <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        Loading...
      </div>
    ); // Show a loading state while fetching data
  }

  return (
    <div className="news-details-page-container">
      <Helmet>
        <title>{newsDetails.title.rendered} - DemandTheHAM</title>
        <meta property="og:title" content={`${newsDetails.title.rendered} - Nick Test`} />
        <meta property="og:description" content="#DemandTheHAM!" />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c8a5e376-1280-4be7-b0d4-c60a65d55836/44b6fe29-9b32-4a55-a492-d8ad2dcd12ae?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
     
      <div
        id="DTH_Epi_Featured_Img"
        className="news-details-page-container1"
        style={{ backgroundImage: `url(${newsDetails.better_featured_image ? newsDetails.better_featured_image.source_url : '6513d74e9b7ca76d70274ee327f06d3b.jpg'})` }}
      ></div>
      <div className="news-details-page-container2">
        <div className="news-details-page-feature-card">
          <h2 id="DTH_News_Title" className="news-details-page-text">
            {newsDetails.title.rendered}
          </h2>
          <span id="DTH_News_Descript" className="news-details-page-text1">
            <div dangerouslySetInnerHTML={{ __html: newsDetails.content.rendered }}></div>
          </span>
        </div>
      </div>
      <div className="news-details-page-container3"></div>
      
    </div>
  );
};

export default NewsDetailsPage;