import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from "react-router-dom"

const SpankBankGallery = (props) => {
  const history = useHistory()

  const handleClick = () => {
    const postId = props.onClickValue; // Ensure onClickValue is correctly accessed
    if (postId) {
      history.push(`/SpankBank/Post?id=${postId}`);
    } else {
      console.error('onClickValue is not defined');
    }
  }

  return (
    <>
      <div className="spank-bank-gallery-container" onClick={handleClick}>
        <img
          src={props.imageSrc}
          alt={props.imageAlt3}
          className="spank-bank-gallery-image"
        />
        <span className="spank-bank-gallery-text">{props.picTitle}</span>
      </div>
      <style jsx>
        {`
          .spank-bank-gallery-container {
            width: 507px;
            height: 364px;
            display: flex;
            position: relative;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            background-color: #000000;
            cursor: pointer;
          }
          .spank-bank-gallery-image {
            width: 100%;
            height: 336px;
            margin: var(--dl-space-space-halfunit);
            align-self: center;
            object-fit: cover;
          }
          .spank-bank-gallery-text {
            color: #ffffff;
            margin: 0px;
            padding: var(--dl-space-space-halfunit);
            background-color: #616161;
          }
          @media (max-width: 991px) {
            .spank-bank-gallery-container {
              width: 408px;
              height: 272px;
              background-color: #020202;
            }
            .spank-bank-gallery-image {
              width: 100%;
              height: 100%;
              margin: 0px;
            }
            .spank-bank-gallery-text {
              margin: var(--dl-space-space-unit);
              background-color: #000000;
            }
          }
          @media (max-width: 420px) {
            .spank-bank-gallery-container {
              height: 100%;
            }
            .spank-bank-gallery-image {
              margin: 0px;
            }
            .spank-bank-gallery-text {
              color: #ffffff;
              margin: var(--dl-space-space-halfunit);
            }
          }
        `}
      </style>
    </>
  )
}

SpankBankGallery.defaultProps = {
  imageSrc: 'https://play.teleporthq.io/static/svg/default-img.svg',
  imageAlt: 'image',
  imageSrc1: 'https://play.teleporthq.io/static/svg/default-img.svg',
  imageAlt1: 'image',
  imageSrc2: 'https://play.teleporthq.io/static/svg/default-img.svg',
  imageAlt2: 'image',
  imageSrc3: 'https://play.teleporthq.io/static/svg/default-img.svg',
  imageAlt3: 'image',
  picTitle: 'Text',
  onClickValue: '', // Keep the default as an empty string
}

SpankBankGallery.propTypes = {
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  imageSrc1: PropTypes.string,
  imageAlt1: PropTypes.string,
  imageSrc2: PropTypes.string,
  imageAlt2: PropTypes.string,
  imageSrc3: PropTypes.string,
  imageAlt3: PropTypes.string,
  picTitle: PropTypes.string,
  onClickValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
}

export default SpankBankGallery