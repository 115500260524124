// Import the necessary functions from the SDKs
// Import the necessary functions from the SDKs
import { initializeApp } from 'firebase/app';
import { 
  getAuth, onAuthStateChanged,GoogleAuthProvider, signOut, signInWithEmailAndPassword,signInWithPopup, createUserWithEmailAndPassword, FacebookAuthProvider 
} from 'firebase/auth';
import { getDatabase, ref, get, set } from 'firebase/database'; // Correctly import `set` from `firebase/database`

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBo2WS4UQ6Qgo28KuCLJkUEKWvklMnE40k",
  authDomain: "demandthehamcms.firebaseapp.com",
  databaseURL: "https://demandthehamcms-default-rtdb.firebaseio.com",
  projectId: "demandthehamcms",
  storageBucket: "demandthehamcms.appspot.com",
  messagingSenderId: "403860188421",
  appId: "1:403860188421:web:d1ebd775e75fd24c841b6c",
  measurementId: "G-HG5WVCGKM6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const database = getDatabase(app);

// Correctly export the functions
export { 
  auth, database, onAuthStateChanged, signOut, signInWithPopup, 
  createUserWithEmailAndPassword, FacebookAuthProvider, 
  GoogleAuthProvider, ref, get, set, signInWithEmailAndPassword 
};