import React,  { useRef } from 'react';
import { useAudioPlayer } from './Context';

const MoreDrawer = ({ LiveRadioHandler }) => {
  const {
    audioSrc,
    audioTitle,
    audioImg,
  } = useAudioPlayer();

  const cjs = new Castjs();
  const handleCast = () => {
    if (isCasting) {
      cjs.disconnect();
      setIsCasting(false);
      audioRef.current.muted = false;
      audioRef.current.play(); // To resume playback in case it was playing before casting
    } else {
      cjs.cast(audioSrc, {
        title: audioTitle,
        poster: audioImg,
      });
      setIsCasting(true);
      audioRef.current.muted = true;
    }
  };

  
  return (
    <>
      <div className="more-drawer-container">
        <div className="more-drawer-cast" onClick={handleCast}>
          <svg
            viewBox="0 0 1024 1024"
            id="DTH_CastJSLaunch"
            className="more-drawer-icon"
          >
            <path d="M42 426q194 0 332 138t138 332h-86q0-160-113-272t-271-112v-86zM42 598q124 0 212 87t88 211h-86q0-88-63-151t-151-63v-84zM42 768q52 0 90 38t38 90h-128v-128zM896 128q34 0 60 26t26 60v596q0 34-26 60t-60 26h-298v-86h298v-596h-768v128h-86v-128q0-34 26-60t60-26h768z"></path>
          </svg>
        </div>
        <div className="more-drawer-spotify" onClick={() => alert("This functionality isn't available just yet. Working on it :) ")}>
          <svg
            id="DTH_CasttoSpotify"
            viewBox="0 0 877.7142857142857 1024"
            className="more-drawer-icon2"
          >
            <path d="M644 691.429c0-16-6.286-22.286-17.143-29.143-73.714-44-159.429-65.714-255.429-65.714-56 0-109.714 7.429-164 19.429-13.143 2.857-24 11.429-24 29.714 0 14.286 10.857 28 28 28 5.143 0 14.286-2.857 21.143-4.571 44.571-9.143 91.429-15.429 138.857-15.429 84 0 163.429 20.571 226.857 58.857 6.857 4 11.429 6.286 18.857 6.286 14.286 0 26.857-11.429 26.857-27.429zM698.857 568.571c0-15.429-5.714-26.286-20-34.857-87.429-52-198.286-80.571-313.143-80.571-73.714 0-124 10.286-173.143 24-18.286 5.143-27.429 17.714-27.429 36.571s15.429 34.286 34.286 34.286c8 0 12.571-2.286 21.143-4.571 40-10.857 88-18.857 143.429-18.857 108.571 0 207.429 28.571 278.857 70.857 6.286 3.429 12.571 7.429 21.714 7.429 19.429 0 34.286-15.429 34.286-34.286zM760.571 426.857c0-21.143-9.143-32-22.857-40-98.857-57.714-234.286-84.571-363.429-84.571-76 0-145.714 8.571-208 26.857-16 4.571-30.857 18.857-30.857 42.286 0 23.429 17.714 41.714 41.143 41.714 8.571 0 16.571-2.857 22.857-4.571 55.429-15.429 115.429-21.143 175.429-21.143 118.857 0 242.286 26.286 321.714 73.714 8 4.571 13.714 6.857 22.857 6.857 21.714 0 41.143-17.143 41.143-41.143zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
          </svg>
        </div>
        <div className="more-drawer-live-radio" onClick={LiveRadioHandler}>
          <svg
            viewBox="0 0 1024 1024"
            id="DTH_Live_Radio"
            className="more-drawer-icon4"
          >
            <path d="M854 512v-170h-684v170h512v-86h86v86h86zM298 854q52 0 90-38t38-90-38-90-90-38-90 38-38 90 38 90 90 38zM138 262l540-220 28 72-352 142h500q36 0 60 25t24 61v512q0 36-24 60t-60 24h-684q-36 0-60-24t-24-60v-512q0-60 52-80z"></path>
          </svg>
        </div>
      </div>
      <style jsx>
        {`
          .more-drawer-container {
            gap: 0;
            width: auto;
            height: auto;
            display: flex;
            padding: var(--dl-space-space-unit);
            position: relative;
            flex-wrap: wrap;
            align-items: flex-start;
            border-color: var(--dl-color-gray-black);
            border-width: 1px;
            border-radius: var(--dl-radius-radius-radius4);
            flex-direction: row;
            justify-content: flex-start;
            background-color: #ffffff;
            border-top-left-radius: var(--dl-radius-radius-radius8);
            border-top-right-radius: var(--dl-radius-radius-radius8);
            border-bottom-left-radius: var(--dl-radius-radius-radius8);
            border-bottom-right-radius: var(--dl-radius-radius-radius8);
          }
          .more-drawer-cast {
            gap: var(--dl-space-space-halfunit);
            flex: 0 0 auto;
            width: auto;
            height: auto;
            margin: var(--dl-space-space-halfunit);
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            border-color: rgba(120, 120, 120, 0.4);
            border-style: dashed;
            border-width: 0px;
            justify-content: center;
          }
          .more-drawer-icon {
             fill: #d9d9d9
            width: 32px;
            height: 32px;
            margin: var(--dl-space-space-halfunit);
            padding: 0px;
          }
          .more-drawer-spotify {
            gap: var(--dl-space-space-halfunit);
            flex: 0 0 auto;
            width: auto;
            height: auto;
            margin: var(--dl-space-space-halfunit);
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            border-color: rgba(120, 120, 120, 0.4);
            border-style: dashed;
            border-width: 0px;
            justify-content: center;
          }
          .more-drawer-icon2 {
            fill: #d9d9d9;
            width: 32px;
            height: 32px;
            margin: var(--dl-space-space-halfunit);
            padding: 0px;
          }
          .more-drawer-live-radio {
            gap: var(--dl-space-space-halfunit);
            flex: 0 0 auto;
            width: auto;
            height: auto;
            margin: var(--dl-space-space-halfunit);
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            border-color: rgba(120, 120, 120, 0.4);
            border-style: dashed;
            border-width: 0px;
            justify-content: center;
          }
          .more-drawer-icon4 {
            width: 32px;
            height: 32px;
            margin: var(--dl-space-space-halfunit);
          }
        `}
      </style>
    </>
  );
}

export default MoreDrawer;