import React from 'react'
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types'
import { useAudioPlayer } from './Context';

import './dthepicard.css'

const DTHEPICARD = (props) => {
  const history = useHistory()

  const episodeDetailHandler = () => {
    const { CardTitle } = props;
    history.push(`/episode?title=${CardTitle}`);
  };

  const { handleOnClick, isLive,
          setIsLive,isPlaying,
          setIsPlaying,  setAudioSrc, setAudioTitle} = useAudioPlayer();
  
  const handleCardClick = () => {
    if (isPlaying) {
      setIsPlaying(false); // Stop the audio
      setAudioSrc(''); // Unload the current audio source
      setAudioTitle(''); // Clear the audio title

      // Set the new audio source for the clicked item after a slight delay to ensure state updates
      setTimeout(() => {
        setAudioSrc(props.url);
        setIsPlaying(true); // Play the new audio automatically
        setAudioTitle(props.CardTitle); // Set the audio title
      }, 100); // Adjust the delay time as necessary
    } else {
      // Set the new audio source for the clicked item directly
      setAudioSrc(props.url);
      setIsPlaying(true); // Play the new audio automatically
      setAudioTitle(props.CardTitle); // Set the audio title
    }
  };
  
  return (
    <div style={{ backgroundImage: `url(${props.BgImage || 'https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/HAMFallBack.jpg'})` }} className={`dthepicard-container ${props.rootClassName} `}>
      <div className="dthepicard-container1"onClick={episodeDetailHandler}>
        <span className="dthepicard-text">{props.CardTitle}</span>
        <span className="dthepicard-text1">{props.EpisodeDescript}</span>
      </div>
      <div className="dthepicard-container2" onClick={handleCardClick}>
        <svg viewBox="0 0 1024 1024" className="dthepicard-icon">
          <path d="M342 214l468 298-468 298v-596z" className=""></path>
        </svg>
      </div>
    </div>
  )
}

DTHEPICARD.defaultProps = {
  imageSrc: '702e5575-321f-4a2f-af21-5401694435a6',
  imageSrc4: '97e9642c-d71e-48bf-8544-e3a8db9747b2',
  imageAlt2: 'image',
  imageAlt4: 'image',
  imagesrc5: 'https://play.teleporthq.io/static/svg/default-img.svg',
  imageSrc3: '103b4710-b523-44cc-9807-ac7217ec8c41',
  imagealt5: 'image',
  text2: 'Text',
  imageSrc1: '103b4710-b523-44cc-9807-ac7217ec8c41',
  imageAlt3: 'image',
  imageAlt: 'image',
  rootClassName: '',
  imageAlt1: 'image',
  text1: 'Text',
  imageSrc2: '702e5575-321f-4a2f-af21-5401694435a6',
  EpisodeDescript: 'Description',
  CardTitle: 'EpisodeName',
  heading: 'Heading',
  text: 'Text',
  url:'',
  BgImage:'https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/HAMFallBack.jpg',
}

DTHEPICARD.propTypes = {
  imageSrc: PropTypes.string,
  imageSrc4: PropTypes.string,
  imageAlt2: PropTypes.string,
  imageAlt4: PropTypes.string,
  imagesrc5: PropTypes.string,
  imageSrc3: PropTypes.string,
  imagealt5: PropTypes.string,
  text2: PropTypes.string,
  imageSrc1: PropTypes.string,
  imageAlt3: PropTypes.string,
  imageAlt: PropTypes.string,
  rootClassName: PropTypes.string,
  imageAlt1: PropTypes.string,
  text1: PropTypes.string,
  imageSrc2: PropTypes.string,
  EpisodeDescript: PropTypes.string,
  CardTitle: PropTypes.string,
  heading: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  BgImage: PropTypes.string,
}

export default DTHEPICARD
