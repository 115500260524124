import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './crew-page.css';
import CrewCard from '../components/CrewCard';



const CrewPage = () => {
  const [crewData, setCrewData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://wizzvzmqudvvbgzkrhrt.supabase.co/rest/v1/CrewBios', {
          headers: {
            'apiKey': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Indpenp2em1xdWR2dmJnemtyaHJ0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODg0OTkyNzIsImV4cCI6MjAwNDA3NTI3Mn0.MsWGlJzP0wyx4MrwTo5RZpzlSAxgypPG41V_w-cRnOI',
            'X-DTH-Platform': 'DTHWEB'
          },
        });
        setCrewData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="top-margin"></div> {/* Adds margin to the top of the page */}
      <div className="crew-cards-container">
        {
          crewData.map((crew) => (
            <CrewCard
              key={crew.id}
              crewName={crew.Name}
              crewPosition={crew.Job}
              backgroundImage={crew.ImageSrc}
              className="crew-card"
            />
          ))
        }

        
      </div>
    </>
  );
};

export default CrewPage;