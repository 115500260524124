import React from 'react'

import { Helmet } from 'react-helmet'


import './speical-feature.css'

const SpeicalFeature = (props) => {
  return (
    <div className="speical-feature-container">
      <Helmet>
        <title>SpeicalFeature - Nick Test</title>
        <meta property="og:title" content="SpeicalFeature - Nick Test" />
        <meta property="og:description" content="#DemandTheHAM!" />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c8a5e376-1280-4be7-b0d4-c60a65d55836/44b6fe29-9b32-4a55-a492-d8ad2dcd12ae?org_if_sml=1"
        />
      </Helmet>
      <iframe
        id="DTH_Page_Special"
        src="DTH.feature.elementor"
        className="speical-feature-iframe"
      ></iframe>
    </div>
  )
}

export default SpeicalFeature
