import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Abouttimeline from '../components/abouttimeline'
import './about.css'

const About = (props) => {
  return (
    <div className="about-container">
      <Helmet>
        <title>About - Nick Test</title>
        <meta property="og:title" content="About - Nick Test" />
        <meta property="og:description" content="#DemandTheHAM!" />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c8a5e376-1280-4be7-b0d4-c60a65d55836/44b6fe29-9b32-4a55-a492-d8ad2dcd12ae?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Abouttimeline
        text={
          <Fragment>
            <span className="about-text1">
              It all started at Connecticut school of broadcasting “in an empty
              room” and has developed into the most dangerous radio show on the
              internet, armed with weapons of mass discussion. Tackling the hot
              button topics or chatting it up with the biggest stars (and even
              the smallest ones too!) in the entertainment industry or having a
              vuvuzela go where its never been before! It’s raw, it’s edgy, It’s
              completely Uncensored, it’s The Ham Radio Show.
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="about-text2">
              But Of Course, Don&apos;t Take Our Word For It....
            </span>
          </Fragment>
        }
        rootClassName="abouttimelineroot-class-name"
      ></Abouttimeline>
    </div>
  )
}

export default About
