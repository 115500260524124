import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const EraSelector = (props) => {
  let history = useHistory();

  let backgroundStyle = {};
  if (props.Erastation === 'GOOM Radio') {
    backgroundStyle = { backgroundImage: `url("https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/Goomradio.png")` };
  } else if (props.Erastation === 'EdgeFM') {
    backgroundStyle = { backgroundImage: `url("https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/edgefm.png")` };
  } else if (props.Erastation === 'MLR') {
    backgroundStyle = { backgroundImage: `url("https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/MLR.jpg")` };
  } else if (props.Erastation === 'PPR') {
    backgroundStyle = { backgroundImage: `url("https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/PPR.jpeg")` };
  } else if (props.Erastation === 'UFRN') {
    backgroundStyle = { backgroundImage: `url("https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/UFRN.jpg")` };
  }

  function handleClick() {
    history.push(`/era?station=${encodeURIComponent(props.Erastation)}`);
  }

  return (
    <div className="era-selector" onClick={handleClick} style={backgroundStyle}>
      <span className="era-selector-text">{props.Erastation}</span>
      <style jsx>{`
        .era-selector {
          flex: 1 1 20%; /* Flexible and base size */
          max-width: 24%; /* Max width for larger screens */
          min-width: 300px; /* Min width for smaller screens */
          margin: 8px; /* Margin around each card */
          padding: 8px; /* Padding inside each card */
          height: 300px; /* Fixed height */
          display: flex; /* Flex display to center content */
          justify-content: center; /* Center content horizontally */
          align-items: center; /* Center content vertically */
          background: rgba(255, 255, 255, 0.8);
          background-size: auto;
          border: 1px solid #ddd;
          border-radius: 8px;
          background-repeat: no-repeat; /* Prevent background image repeat */
          
          background-size: contain;
          background-position: center; /* Center the background image */
          background-image:  url('') ,linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)); /* Gradient overlay with 50% transparency */
        }
        .era-selector-text {
          display: block;
          width: auto;
          text-align: center;
          color: whitesmoke;
          background-color: rgba(0, 0, 0, 0.8);
          padding: 8px;
          font-weight: bold;
        }
        @media (max-width: 768px) {
          .era-selector {
            flex: 1 1 48%; /* Two cards per row on smaller screens */
            max-width: 48%;
          }
        }
        @media (max-width: 480px) {
          .era-selector {
            flex: 1 1 100%; /* One card per row on very small screens */
            max-width: 100%;
          }
        }
      `}</style>
    </div>
  );
};

EraSelector.defaultProps = {
  Erastation: '',
};

EraSelector.propTypes = {
  Erastation: PropTypes.string,
};

export default EraSelector;
