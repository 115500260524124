import React from 'react'

import { Helmet } from 'react-helmet'


import DTHFooter from '../components/dt-footer'
import './error.css'

const Error = (props) => {
  return (
    <div className="error-container">
      <Helmet>
        <title>Error - Nick Test</title>
        <meta property="og:title" content="Error - Nick Test" />
        <meta property="og:description" content="#DemandTheHAM!" />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c8a5e376-1280-4be7-b0d4-c60a65d55836/44b6fe29-9b32-4a55-a492-d8ad2dcd12ae?org_if_sml=1"
        />
      </Helmet>
      <div className="error-container1"></div>
      <div className="error-container2"></div>
      <h1 className="error-text">
        <span className="error-text1">NOT FOUND</span>
        <br className="error-text2"></br>
        <span className="error-text3">
          Don&apos;t You Hate it when that happens?
        </span>
        <br></br>
      </h1>
      <DTHFooter rootClassName="dt-footer-root-class-name4"></DTHFooter>
    </div>
  )
}

export default Error
