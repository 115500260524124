import React,{ useContext } from 'react'
import { useAudioPlayer } from './Context';

import { Link } from 'react-router-dom';


import PropTypes from 'prop-types'

const DTHPWAMobilePlayer = (props) => {
  const {
    audioTitle,
    setAudioTitle,
    audioImg,
    setAudioImg

  } = useAudioPlayer();
  
  return (
    <>
      <div className="dthpwa-mobile-player-container">
        <div className="dthpwa-mobile-player-container1">
<Link to="/">
          
          <img
            src="https://app.thehamradio.show/wp-content/uploads/HAMLOGO.png"
            alt={props.imageAlt15}
            className="dthpwa-mobile-player-image"
          />
  </Link>
        </div>
        <header className="dthpwa-mobile-player-container2">
          <span className="dthpwa-mobile-player-text">
            {audioTitle}
          </span>
          <span className="dthpwa-mobile-player-text1">Why? Because We can!</span>
        </header>
      </div>
      <style jsx>
        {`
          .dthpwa-mobile-player-container {
            width: 100%;
            height: 400px;
            display: flex;
            position: relative;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
          }
          .dthpwa-mobile-player-container1 {
            flex: 0 0 auto;
            width: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .dthpwa-mobile-player-image {
            width: 200px;
            object-fit: cover;
          }
          .dthpwa-mobile-player-container2 {
            flex: 0 0 auto;
            width: 100vw;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          @media (max-width: 450px) {
            .dthpwa-mobile-player-container {
              width: 100vw;
              height: 130px;
              align-items: center;
              flex-direction: row;
              justify-content: flex-start;
              background-color: #000000;
            }
            .dthpwa-mobile-player-container1 {
              width: 25%;
              height: 100%;
              border-style: hidden;
            }
            .dthpwa-mobile-player-image {
              width: auto;
              height: 78px;
              margin: var(--dl-space-space-halfunit);
              padding: var(--dl-space-space-halfunit);
            }
            .dthpwa-mobile-player-container2 {
              width: 75%;
              height: 100%;
              align-items: center;
              border-style: hidden;
              flex-direction: column;
              background-size: cover;
              justify-content: center;
              background-image: url('https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/hamYT.png');
              background-repeat: no-repeat;
              background-position: top right;
            }
            .dthpwa-mobile-player-text {
              padding: var(--dl-space-space-halfunit);
              background-color: #d9d9d9;
            }
            .dthpwa-mobile-player-text1 {
              color: #ffffff;
              margin: var(--dl-space-space-halfunit);
              padding: var(--dl-space-space-halfunit);
              background-color: #000000;
            }
          }
        `}
      </style>
    </>
  )
}

DTHPWAMobilePlayer.defaultProps = {
  subtext: '73dc0f37-9db1-46a5-90da-e23140a320c3',
  imageSrc1: '97e9642c-d71e-48bf-8544-e3a8db9747b2',
  imageSrc4: '97e9642c-d71e-48bf-8544-e3a8db9747b2',
  imageSrc3: '342f9fd4-a7af-429b-a6d9-845ad34cae24',
  imageSrc15: '/hamlogo115-yjtf-200h.png',
  imageAlt15: 'image',
  mobileHeaderTitlle: 'Ham Radio Show',
  header: 'HeaderSubText',
}

DTHPWAMobilePlayer.propTypes = {
  subtext: PropTypes.string,
  imageSrc1: PropTypes.string,
  imageSrc4: PropTypes.string,
  imageSrc3: PropTypes.string,
  imageSrc15: PropTypes.string,
  imageAlt15: PropTypes.string,
  mobileHeaderTitlle: PropTypes.string,
  header: PropTypes.string,
}

export default DTHPWAMobilePlayer
