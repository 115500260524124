import React, { createContext, useState, useContext } from 'react';

// Create the context
const AudioPlayerContext = createContext();

// Provide the context
const AudioPlayerProvider = ({ children }) => {
  const [audioSrc, setAudioSrc] = useState('');
  const [audioTitle, setAudioTitle] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLive, setIsLive] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [TPBGImage, SetTPBGImage] = useState('');
  const [BGImage, SetBGImage] = useState('');
  const [audioSubtext, setAudioSubtext]= useState('');
  const [isActiveFav, setIsActiveFav] = useState(false);
  const [isFav, setIsFav] = useState(false);
  
  const handleOnClick = (url, title) => {
    setAudioSrc(url);
    setAudioTitle(title);
    setIsPlaying(true);
  };
  return (
    <AudioPlayerContext.Provider
      value={{
        audioSrc,
        setAudioSrc,
        audioTitle,
        setAudioTitle,
        isPlaying,
        setIsPlaying,
        isLive,
        setIsLive,
        duration,
        setDuration,
        currentTime,
        setCurrentTime,
        TPBGImage,
        SetTPBGImage,
        BGImage,
        SetBGImage,
        audioSubtext,
          setAudioSubtext,
        isActiveFav,
        setIsActiveFav,
        isFav,
          setIsFav,
        handleOnClick
        
      }}
    >
      {children}
    </AudioPlayerContext.Provider>
  );
};

// Custom hook to use the AudioPlayerContext
const useAudioPlayer = () => {
  const context = useContext(AudioPlayerContext);
  if (!context) {
    throw new Error('useAudioPlayer must be used within a AudioPlayerProvider');
  }
  console.log(context); // Inspect the state here
  return context;
};

export { AudioPlayerProvider, useAudioPlayer, AudioPlayerContext };